import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { catchError, filter, map, merge, of, switchMap, tap } from 'rxjs';
import {
  DeviceTypeDto,
  Source,
} from '../../../../../models/device-type.models';
import { DeviceDto } from '../../../../../models/device.models';
import { DevicesService } from '../../../../services/http/devices.service';
import { AuthService } from '../../../../services/state/auth/auth.service';
import { DataLoaderService } from '../../../../services/state/data/data-loader.service';
import { DataStoreService } from '../../../../services/state/data/data-store.service';
import { DeviceManagementService } from '../device-management.service';

const sortDeviceTypes = (a: DeviceTypeDto, b: DeviceTypeDto) => {
  if (
    `${a.brand.brand_id} - ${a.metadata.label}` <
    `${b.brand.brand_id} - ${b.metadata.label}`
  ) {
    return -1;
  } else if (
    `${a.brand.brand_id} - ${a.metadata.label}` >
    `${b.brand.brand_id} - ${b.metadata.label}`
  ) {
    return 1;
  } else {
    return 0;
  }
};

interface DeviceTableData {
  id: string;
  name: string;
  device_type_id: string;
  device_type_label: string;
  source_id: string;
  source_label: string;
  creation_date: number;
  site_id: string;
  site_label: string;
}

const formatTableDataElement = (
  device: DeviceDto,
  sources: Source[],
): DeviceTableData => {
  return {
    id: device.device_id,
    name: device.metadata.name,
    device_type_id: device.device_type.device_type_id,
    device_type_label: `${device.device_type.brand.brand_id} - ${device.device_type.metadata.label}`,
    source_id: device.source_id,
    source_label: `${sources.find((source) => source.source_id === device.source_id)?.metadata.label}`,
    creation_date: device.metadata.created_at as number,
    site_id: device.site.tag_id,
    site_label: `${device.site?.metadata.iso_code} - ${device.site?.metadata.site_long_name}`,
  };
};

@Component({
  selector: 'app-multi-downlink',
  templateUrl: './multi-downlink.component.html',
  styleUrls: ['./multi-downlink.component.scss'],
})
export class MultiDownlinkComponent implements OnInit {
  //filter
  filterForm = this.buildFilterForm();
  models$ = this.data.deviceTypes$.pipe(
    filter((dt) => !!dt),
    map((dt) => dt!.sort((a, b) => sortDeviceTypes(a, b))),
  );
  sites$ = this.data.sites$;
  sources$ = this.data.sources$;

  //table
  @ViewChild(MatLegacyPaginator, { static: true })
  paginator!: MatLegacyPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  deviceTableData: DeviceTableData[];
  dataSource = new MatTableDataSource<DeviceTableData>();
  selection = new SelectionModel<DeviceTableData>(true, []);
  displayedColumns: string[] = [
    'select',
    'site_label',
    'source_label',
    'device_type_label',
    'id',
    'name',
    'creation_date',
  ];

  //downlink form
  downlinkForm = this.buildDownlinkForm();

  constructor(
    private deviceManagement: DeviceManagementService,
    private router: Router,
    public auth: AuthService,
    private dataLoader: DataLoaderService,
    private data: DataStoreService,
    private snackBar: MatSnackBar,
    public dialog: MatLegacyDialog,
    private deviceHttp: DevicesService,
  ) {
    this._loadData().subscribe();
  }

  ngOnInit(): void {
    this.handleFilterChanges().subscribe();
  }

  openMultiDownlink() {
    this.deviceManagement.setView('multi-downlink');
    this.router.navigateByUrl(`home/device/${this.deviceManagement.view}`);
  }

  openManager() {
    this.deviceManagement.setView('manager');
    this.router.navigate([`/home/device/${this.deviceManagement.view}`], {
      queryParams: { path: this.deviceManagement.path },
    });
  }

  resetForm() {
    this.filterForm.patchValue({
      project_ids: '',
      device_type_id: '',
      source: '',
    });
    this.filterForm.markAsUntouched();
  }

  getPageData() {
    return this.dataSource._pageData(
      this.dataSource._orderData(this.dataSource.filteredData),
    );
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle() {
    this.isEntirePageSelected()
      ? this.selection.clear()
      : this.selection.select(...this.getPageData());
  }

  resetSelection() {
    this.selection.clear();
  }

  get isValidSelection(): boolean {
    const selection = this.selection.selected;
    return this.checkAllFirefly(selection) && this.checkAllSameModel(selection);
  }

  get sendButtonTooltip(): string {
    const selection = this.selection.selected;
    if (!selection.length) {
      return 'Please select at least one device';
    } else if (!this.checkAllFirefly(selection)) {
      return 'Downlink is only available for Firefly (LoRaWAN) devices';
    } else if (!this.checkAllSameModel(selection)) {
      return 'Downlink is only available for devices of the same model';
    }
    return '';
  }

  get resetTooltip() {
    return this.selection.selected.length
      ? 'changing the filter or table page will undo your current selection'
      : '';
  }

  checkAllFirefly(selection: DeviceTableData[]): boolean {
    return selection.every((device) => device.source_id.includes('firefly'));
  }

  checkAllSameModel(selection: DeviceTableData[]): boolean {
    return new Set(selection.map((device) => device.device_type_id)).size === 1;
  }

  public onSendDownlink(templateRef: TemplateRef<unknown>) {
    const successMsg = 'Downlink configuration successfully sent to devices';
    const dialogRef = this.dialog.open(templateRef);

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((res) =>
          res
            ? this.deviceHttp.sendMultiDownlink(
                this.selection.selected.map((device) => device.id),
                this.downlinkForm.value,
              )
            : of(null),
        ),
      )
      .subscribe((res) => {
        if (res) {
          this.snackBar.open(successMsg, 'close');
          this.downlinkForm.reset();
          this.selection.clear();
          this.paginator.firstPage();
        }
      });
  }

  private _loadData() {
    return merge(
      this.dataLoader.loadDevices().pipe(
        map((devices) => {
          const sources = this.data.sources ?? [];
          return devices.map((device) =>
            formatTableDataElement(device, sources),
          );
        }),
        tap((tableData) => {
          this.deviceTableData = tableData;
          this.dataSource = new MatTableDataSource<DeviceTableData>(tableData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }),
      ),
      this.data.deviceTypes$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadDeviceTypes(),
        ),
        catchError(() => of([])),
      ),
      this.data.sites$.pipe(
        switchMap((state) => (state ? of(state) : this.dataLoader.loadSites())),
        catchError(() => of([])),
      ),
      this.data.sources$.pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadSources(),
        ),
        catchError(() => of([])),
      ),
    );
  }

  private handleFilterChanges() {
    return this.filterForm.valueChanges.pipe(
      tap((value) => {
        this.selection.clear();
        this.dataSource = new MatTableDataSource<DeviceTableData>(
          this.filterDeviceTable(value),
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }),
    );
  }

  private buildFilterForm(
    projectIds = [],
    deviceType = '',
    source = '',
  ): FormGroup {
    return new FormGroup({
      project_ids: new FormControl(projectIds),
      device_type_id: new FormControl(deviceType),
      source: new FormControl(source),
    });
  }

  private buildDownlinkForm() {
    return new FormGroup({
      payloadHex: new FormControl('', [
        Validators.required,
        Validators.pattern(/[0-9A-Fa-f]+$/),
      ]),
      targetPorts: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^([1-9]|[1-9][0-9]|1[0-9][0-9]|2[0-1][0-9]|22[0-3])$/,
        ),
      ]),
    });
  }

  private filterDeviceTable(filter): DeviceTableData[] {
    let devices = this.deviceTableData;
    if (devices) {
      if (filter.project_ids.length) {
        devices = devices.filter((device) =>
          filter.project_ids.includes(device.site_id),
        );
      }

      if (filter.source) {
        devices = devices.filter(
          (device) => device.source_id === filter.source,
        );
      }

      if (filter.device_type_id) {
        devices = devices.filter(
          (device) => device.device_type_id === filter.device_type_id,
        );
      }
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    return devices ?? [];
  }
}
