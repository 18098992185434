<div
  class="container"
  *ngIf="tableData.length && columnsToDisplay.length; else noData"
  #containerEl
>
  <div class="table">
    <table
      mat-table
      [dataSource]="dataSource"
      aria-describedby="data output preview"
    >
      <ng-container
        [matColumnDef]="column"
        [sticky]="i === 0"
        *ngFor="let column of columnsToDisplay; let i = index"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          id="column"
          [ngClass]="{ timestamp: i === 0 }"
        >
          {{ i === 0 ? 'Received Timestamp' : column }}
        </th>
        <td
          class="truncate-cell"
          mat-cell
          *matCellDef="let row"
          [ngClass]="{ timestamp: i === 0 }"
        >
          {{
            isTimestamp(column)
              ? (row[column] | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ')
              : row[column]
          }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnsToDisplay; sticky: true"
        class="header-row"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        (click)="
          selectedRow = row === selectedRow ? null : row;
          rowSelect.emit(selectedRow)
        "
        [class.selected]="selectedRow === row"
      ></tr>
    </table>
  </div>
</div>

<ng-template #noData class="no-data">
  <h3>No data preview available for this device.</h3>
</ng-template>
