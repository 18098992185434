export const environment = {
  production: false,
  apiUrlExplorer: 'https://iothub-pre-prod.solvay.com/api/v1/explorer/',
  apiUrlConfigs: 'https://iothub-pre-prod.solvay.com/api/v1/configuration/',
  apiUrlCommands: 'https://iothub-pre-prod.solvay.com/api/v1/command/',
  apiUrlTelemetries:
    'https://iothub-pre-prod.solvay.com/api/v1/explorer/telemetries/',
  firebaseConfig: {
    apiKey: 'AIzaSyDlexw6tbjeHNk0Ej6FDoQf0VHMCjTMVkE',
    authDomain: 'prj-iothub-pre-prod.firebaseapp.com',
    databaseURL: 'https://prj-iothub-pre-prod.firebaseio.com',
    projectId: 'prj-iothub-pre-prod',
    storageBucket: 'prj-iothub-pre-prod.appspot.com',
    messagingSenderId: '806613637611',
    appId: '1:806613637611:web:65fc3f3c18807ebf1221ae',
  },
  googleSheetBaseUrl: 'https://docs.google.com/spreadsheets/d/',
  grafanaUrl: 'https://35.205.95.115/',
  supportGoogleSheetUrl:
    'https://docs.google.com/spreadsheets/d/11pN9PYlnGZssPa3mJf9vFpoRcuWNfT8JQURaZ-JsBv8/edit#gid=0',
  gsExporterServiceAccount: 'prj-iothub-pre-prod@appspot.gserviceaccount.com',
  openStreetMap: {
    defaultMapCenter: {
      lat: 50.901072,
      lng: 4.400331,
    },
    layerTilesUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  serviceAccountGoogleSheet: 'prj-iothub-pre-prod@appspot.gserviceaccount.com',
  stage: 'pre-prod',
};
